/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    a: "a",
    strong: "strong",
    ol: "ol",
    li: "li",
    br: "br",
    ul: "ul"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.h2, {
      children: "MANA KREDĪTVĒSTURE"
    }), "\n", _jsx(_components.p, {
      children: "Kredītinformācijas Birojs izveidojis pakalpojumu, kas privātpersonai dod iespēju pārliecināties par informāciju, kas par viņu atrodama Kredītinformācijas Biroja sistēmā, kā arī citos ārējos reģistros. Uzņēmumi un ārējie reģistri, kuru dati tiek iekļauti Kredītinformācijas Biroja sistēmā, uzskaitīti sadaļā “Kredītinformācijas avoti”."
    }), "\n", _jsxs(_components.p, {
      children: ["Kredītinformācijas Birojs katram klientam nodrošina bezmaksas Kredītvēstures ziņojumu neierobežotu skaitu reižu to sniedzot interneta mājas lapā ", _jsx(_components.a, {
        href: "https://www.manakreditvesture.lv",
        children: "www.manakreditvesture.lv"
      }), ". Ja vēlaties saņemt papildus pakalpojumus, tad Jums ir iespēja iegādāties “Gada Abonements” un “Gada Abonements Plus”."]
    }), "\n", _jsxs(_components.p, {
      children: ["Informāciju var saņemt klātienē Rīgā, Grēdu ielā 4a ņemot līdzi derīgu pasi vai personas apliecību (eID). Ja nav iespēja pārbaudīt savu informāciju mājas lapā ", _jsx(_components.a, {
        href: "https://www.manakreditvesture.lv",
        children: "www.manakreditvesture.lv"
      }), " vai saņemt pakalpojumu klātienē Rīgā, Grēdu ielā 4a, atbilstoši likumam par Kredītinformācijas birojiem 19. panta 2. daļai, privātpersona var saņemt ziņas pa pastu, sedzot Kredītinformācijas Biroja izdevumus par pasta pakalpojumiem saskaņā ar pasta pakalpojumu sniedzēja tarifiem."]
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Ja attēlotie dati neatbilst patiesībai vai ir kļūdaini, sazinieties ar mums un informējiet par datiem atbildīgo iestādi!"
      })
    }), "\n", _jsx(_components.h2, {
      children: "KREDĪTINFORMĀCIJAS ATSKAIŠU PIEPRASĪJUMU VĒSTURE"
    }), "\n", _jsx(_components.p, {
      children: "Kredītinformācijas Birojs, ievērojot privātpersonu intereses, izveidojis datu apmaiņas sistēmu, kura uzskaita katru pieprasījumu par katru privātpersonu."
    }), "\n", _jsx(_components.p, {
      children: "Šī ir iespēja kontrolēt Jūsu datu izmantošanas likumību un pamatotību, jo Jums tiek nodrošināta iespēja pārliecināties, ka Jūsu dati tiek izmantoti likumīgi."
    }), "\n", _jsxs(_components.p, {
      children: ["Informāciju par to, vai Jūsu kredītinformācija ir tikusi pārbaudīta, var saņemt divas reizes gadā bez maksas interneta vietnē ", _jsx(_components.a, {
        href: "https://www.manakreditvesture.lv",
        children: "www.manakreditvesture.lv"
      }), ". Šeit Jums būs iespēja saņemt informāciju par to, kura juridiskā persona un kad (datums un laiks) pārbaudīja Jūsu kredītvēsturi."]
    }), "\n", _jsx(_components.p, {
      children: "Saskaņā ar LR normatīvajiem aktiem informāciju par Jums drīkst pieprasīt tikai gadījumos, ja tam ir pamatojums un ir saņemta Jūsu atļauja pārbaudīt šo informāciju. Ja Jūs neesat devis piekrišanu savas Kredītvēstures pārbaudei vai uzņēmums, kurš ir pārbaudījis Jūsu Kredītvēsturi, nav ar Jums noslēdzis līgumu par pakalpojumu vai preču sniegšanu uz nomaksu vai pēcapmaksu, Jums par notikušo faktu jāinformē AS Kredītinformācijas Birojs. Tāpat Jums ir tiesības vērsties Datu valsts inspekcijā ar sūdzību pret konkrēto juridisko personu (uzņēmumu) par neatļautu Jūsu Kredītvēstures pārbaudi."
    }), "\n", _jsx(_components.h2, {
      children: "ATSKAITES"
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.a, {
        href: "https://www.manakreditvesture.lv",
        children: "www.manakreditvesture.lv"
      }), " nodrošina iespēju saņemt Kredītinformācijas Biroja sistēmā esošās ziņas par sevi sekojošā formātā:"]
    }), "\n", _jsxs(_components.ol, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Bezmaksas izziņa:", _jsx(_components.br, {}), "\n", "Jums ir pieejams neierobežots skaits bezmaksas izziņas, kas ietver visas ziņas par Jūsu Kredītvēsturi no Kredītinformācijas Biroja sistēmas dalībniekiem. Atskaite sastāv no sekojošām sadaļām:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "uz Jūsu vārda reģistrētās aktīvās saistības;"
          }), "\n", _jsx(_components.li, {
            children: "uz Jūsu vārda reģistrētie nenomaksātie parādi;"
          }), "\n", _jsx(_components.li, {
            children: "uz Jūsu vārda reģistrētie nomaksātie parādi;"
          }), "\n", _jsx(_components.li, {
            children: "Jūsu datu pieprasījumu statistika."
          }), "\n"]
        }), "\n"]
      }), "\n", _jsxs(_components.li, {
        children: ["Gada abonements", _jsx(_components.br, {}), "\n", "Papildu bezmaksas izziņai gada abonements ietver:", "\n", _jsxs(_components.ul, {
          children: ["\n", _jsx(_components.li, {
            children: "Pilnu interaktīvo atskaiti;"
          }), "\n", _jsx(_components.li, {
            children: "Informāciju par Jūsu kredītreitingu."
          }), "\n"]
        }), "\n"]
      }), "\n"]
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
